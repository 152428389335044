import { extendTheme } from '@chakra-ui/react';
import {
  BG_SECONDARY,
  BG_PRIMARY,
  TEXT_PRIMARY,
  TEXT_ALTERNATIVE,
  TEXT_SECONDARY,
} from '@/constants/colors';
import { FONT_FAMILY } from '@/constants/default-props';

/**
 * override chackra-ui for personalize purpose
 * see the docs: https://chakra-ui.com/guides/using-fonts
 */
const theme = {
  fonts: {
    heading: FONT_FAMILY.heading,
    subHeading: FONT_FAMILY.subHeading,
    body: FONT_FAMILY.body,

    // QR Purpose
    jost: `'Jost', sans-serif;`,
  },
  fontSizes: {
    heading: '36px',
    subHeading: '60px',
    body: '16px',
  },
  fontWeights: {
    heading: 400,
    subHeading: 400,
    body: 400,
  },
  styles: {
    global: {
      ':root': {
        '--fontItalic-heading': '',
        '--fontItalic-subHeading': '',
        '--fontItalic-body': '',
      },
    },
  },
  colors: {
    bgPrimary: 'rgba(255,255,247,1)',
bgSecondary: 'rgba(134,15,15,1)',
mainColorText: '#8D3232',
secondaryColorText: 'rgba(253,253,253,1)',
alternativeColorText: 'rgba(161,26,26,1)',

    transparent: 'transparent',
  },
};

export default extendTheme(theme);
