/////////////////////////////////////////////
// DEFAULT PROPS FOR COMPONENTS
/////////////////////////////////////////////

export const FONT_FAMILY = {
  heading: `'Pinyon Script', cursive;`,
  subHeading: `'Pinyon Script', cursive;`,
  body: `'EB Garamond', serif;`,
};

export const TEXT_PROPS = {
  heading: {
    fontFamily: 'heading',
    fontSize: 'heading',
    fontWeight: 'heading',
    fontStyle: 'var(--fontItalic-heading)',
    color: 'mainColorText',
    textAlign: 'center',
  },
  subHeading: {
    fontFamily: 'subHeading',
    fontSize: 'subHeading',
    fontWeight: 'subHeading',
    fontStyle: 'var(--fontItalic-subHeading)',
    color: 'mainColorText',
    textAlign: 'center',
  },
  body: {
    fontFamily: 'body',
    fontSize: 'body',
    fontWeight: 'body',
    fontStyle: 'var(--fontItalic-body)',
    color: 'mainColorText',
    textAlign: 'center',
  },
};

export const PRIMARY_BUTTON_PROPS = {
  bgColor: 'bgPrimary',
  color: 'mainColorText',
  fontFamily: 'body',
  size: 'sm',
  fontWeight: 'light',
  _active: { opacity: '0.8' },
  _hover: { opacity: '0.8' },
};

export const SECONDARY_BUTTON_PROPS = {
  ...PRIMARY_BUTTON_PROPS,
  bgColor: 'bgSecondary',
  color: 'secondaryColorText',
};
